import emailjs from "@emailjs/browser";
import {
  Box,
  Button,
  FormControl,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const Form = ({ setSuccess }) => {
  const [sendTo, setSendTo] = useState("website@sivoo.com");
  const [isRecaptchaChecked, setIsRecaptchaChecked] = useState(false);
  const recaptchaRef = useRef();

  const handleEmailChange = (e) => {
    setSendTo(e.target.value);
  };

  const handleRecaptchaChange = () => {
    setIsRecaptchaChecked(true);
  };
  const handleRecaptchaExpired = () => {
    setIsRecaptchaChecked(false);
  };

  const form = useRef();

  const handleSend = (e) => {
    e.preventDefault();

    if (isRecaptchaChecked) {
      emailjs
        .sendForm(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          form.current,
          process.env.REACT_APP_PUBLIC_KEY
        )
        .then(
          (result) => {
            if (result.status === 200 || result.text === "OK") {
              setSuccess(true);
            }
          },
          (error) => {
            console.log(error.text);
            setSuccess(false);
          }
        );
    } else {
      console.log("reCAPTCHA verification failed");
    }
  };
  return (
    <div>
      <form ref={form} onSubmit={handleSend}>
        <Box sx={{ my: 2 }}>
          <FormControl fullWidth>
            <InputLabel
              sx={{
                fontSize: { xs: 14, md: 18 },
              }}
              id="send_to"
            >
              Department
            </InputLabel>
            <Select
              labelId="send_to"
              id="send_to"
              value={sendTo}
              label="Department"
              onChange={handleEmailChange}
              variant="outlined"
              name="send_to"
              sx={{
                fontSize: { xs: 14, md: 16 },
              }}
            >
              <MenuItem
                sx={{
                  fontSize: { xs: 14, md: 16 },
                }}
                value="pr@sivoo.com"
                defaultChecked
              >
                Press/Media
              </MenuItem>
              <MenuItem
                sx={{
                  fontSize: { xs: 14, md: 16 },
                }}
                value="ir@sivoo.com"
              >
                Investment Inquiry
              </MenuItem>
              <MenuItem
                sx={{
                  fontSize: { xs: 14, md: 16 },
                }}
                value="sales@sivoo.com"
              >
                Product Sales
              </MenuItem>
              <MenuItem
                sx={{
                  fontSize: { xs: 14, md: 16 },
                }}
                value="website@sivoo.com"
              >
                General Feedback
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Stack sx={{ width: "100%" }} direction="column" gap={2}>
          {/* name */}
          <Stack sx={{ width: "100%" }} direction="column" gap={1}>
            <Typography sx={{ textTransform: "uppercase" }} variant="subtitle1">
              FULL NAME
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: 40,
                mx: "auto",
                border: 0,
                bgcolor: "#363841",
                borderRadius: "2px",
                p: 1,
                px: 2,
              }}
            >
              <InputBase
                fullWidth
                type="text"
                name="user_name"
                required={true}
                sx={{
                  fontSize: { xs: 14, md: 16 },
                }}
              />
            </Box>
          </Stack>
          {/* email */}
          <Stack sx={{ width: "100%" }} direction="column" gap={1}>
            <Typography sx={{ textTransform: "uppercase" }} variant="subtitle1">
              Email
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: 40,
                mx: "auto",
                border: 0,
                bgcolor: "#363841",
                borderRadius: "2px",
                p: 1,
                px: 2,
              }}
            >
              <InputBase
                fullWidth
                type="email"
                name="user_email"
                required={true}
                sx={{
                  fontSize: { xs: 14, md: 16 },
                }}
              />
            </Box>
          </Stack>
        </Stack>
        {/* message */}
        <Stack sx={{ my: 2 }} direction="column" gap={1}>
          <Typography sx={{ textTransform: "uppercase" }} variant="subtitle1">
            MESSAGE
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              mx: "auto",
              border: 0,
              bgcolor: "#363841",
              borderRadius: "2px",
              p: 1,
              px: 2,
            }}
          >
            <InputBase
              multiline
              minRows={4}
              fullWidth
              name="message"
              required={true}
              sx={{
                fontSize: { xs: 14, md: 16 },
              }}
            />
          </Box>
        </Stack>

        <Box>
          <ReCAPTCHA
            theme="dark"
            size="normal"
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_SITE_KEY}
            onChange={handleRecaptchaChange}
            onExpired={handleRecaptchaExpired}
          />
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <Button
            disabled={!isRecaptchaChecked}
            type="submit"
            variant="contained"
            sx={{ my: 2, px: 8 }}
          >
            Send
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default Form;
