import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/Home.module.css";

const Hero = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTab = useMediaQuery(theme.breakpoints.down("md"));

  const [data, setData] = useState({});
  useEffect(() => {
    fetch(`./json/home/hero.json`)
      .then((res) => res.json())
      .then((data) => setData(data));
  }, []);
  return (
    <Box className={styles.heroSection}>
      <video src={data.heroBgVideo} autoPlay loop muted playsInline disablePictureInPicture>
        <source src={data.heroBgVideo} type="video/mp4" />
        <img src={data.heroBgImg} alt="" />
      </video>
      <Box sx={{ px: { xs: 3, md: 8 } }} className={styles.heroContent}>
        <Box sx={{ mt: 4 }}>
          <Typography
            sx={{
              mb: { xs: 1, md: 2 },
            }}
            variant="h1"
          >
            {data.sectionTitle}
          </Typography>
          <Typography
            sx={{
              wordBreak: "break-word",
              whiteSpace: "pre-line",
            }}
            variant="body1"
            gutterBottom
          >
            {data.description}
          </Typography>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            mt: { xs: 2, md: 6 },
            mb: { xs: 2, md: 4 },
            height: 1,
          }}
        >
          <Grid container spacing={1} columns={{ xs: 4, sm: 8, lg: 12 }}>
            {data?.features?.map((feature) => (
              <Grid
                sx={{
                  height: 1,
                }}
                key={feature.id}
                item
                xs={2}
                sm={4}
                lg={3}
              >
                <div>
                  <Link to={feature.link}>
                    <img
                      style={{
                        width: "100%",
                        height: isMobile ? "80px" : isTab ? "120px" : "160px",
                        objectFit: "cover",
                      }}
                      src={feature.image}
                      alt={feature.title}
                    />
                  </Link>
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Hero;
