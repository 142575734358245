import { createBrowserRouter } from "react-router-dom";
import Error from "../components/Error/Error";
import Main from "../layout/Main/Main";
import Contact from "../pages/Main/Contact/Contact";
import Corporate from "../pages/Main/Corporate/Corporate";
import Home from "../pages/Main/Home/Home";
import NotFound from "../pages/Main/NotFound/NotFound";
import PressAndMedia from "../pages/Main/PressAndMedia/PressAndMedia";
import Privacy from "../pages/Main/Privacy/Privacy";
import Services from "../pages/Main/Services/Services";
import Technology from "../pages/Main/Technology/Technology";
import Terms from "../pages/Main/Terms/Terms";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [
      { path: "/", element: <Home /> },

      {
        path: "/press-media",
        element: <PressAndMedia />,
      },
      {
        path: "/technology",
        element: <Technology />,
      },
      {
        path: "/services",
        element: <Services />,
      },
      {
        path: "/corporate",
        element: <Corporate />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/terms",
        element: <Terms />,
      },
      {
        path: "/privacy",
        element: <Privacy />,
      },
      {
        path: "/notfound",
        element: <NotFound />,
      },
    ],
    errorElement: <Error />,
  },
]);

export default routes;
